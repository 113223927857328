import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Card, Button, Form } from "react-bootstrap";
import NavbarComponent from "../../base/navbarComponent";
import { FaPlus } from "react-icons/fa";
import { AiOutlineSave } from "react-icons/ai";
import { getApiData, makeApiRequest } from "../network/network_handler";

interface LessonProps {
  lesson: {
    id: string;
    title: string;
    description: string;
    content: string;
    exercise: string;
    correctAnswer: string;
    language: string;
    category: string;
    unitTest: string;
    showDisplaySolution: boolean;
  };
}

interface LanguageOptions {
  [key: string]: string;
}

interface LanguageOptions {
  HTML: string;
  CSS: string;
  "HTML / CSS": string;
  JavaScript: string;
  Java: string;
  PHP: string;
}

const Lesson: React.FC = () => {
  const navigate = useNavigate();
  const [selectedLesson, setSelectedLesson] = useState<
    LessonProps["lesson"] | null
  >(null);
  const [lessons, setLessons] = useState<LessonProps["lesson"][]>([]);
  const [isLoading, setIsLoading] = useState(true); // Ladezustand
  const [exerciseActive, setExerciseActive] = useState(false); // Übung aktiviert / deaktiviert
  const [unitTestsActive, setUnitTestsActive] = useState(false); // Unit Tests toggle
  const [unitTestsInput, setUnitTestsInput] = useState(""); // Unit Tests input

  const languageOptions: LanguageOptions = {
    HTML: "html",
    CSS: "css",
    "HTML / CSS": "htmlCss",
    JavaScript: "js",
    Java: "java",
    PHP: "PHP",
  };

  const getLanguageValue = (language: string) => {
    const mappedLanguage = Object.keys(languageOptions).find(
      (key) => languageOptions[key] === language
    );
    return mappedLanguage ? languageOptions[mappedLanguage] : "Andere Sprachen";
  };

  const newLesson2 = {
    id: (lessons.length + 1).toString(),
    title: "",
    description: "",
    content: "",
    exercise: "",
    correctAnswer: "",
    language: "",
    category: "",
    unitTest: "", // Added unitTest property
    showDisplaySolution: false,
  };

  let firstLesson = newLesson2;

  const url = new URL(window.location.href);
  const platformID = url.pathname.split("/")[1];

  const handleExerciseToggle = () => {
    setExerciseActive((prevExerciseActive) => !prevExerciseActive);
  };

  useEffect(() => {
    const requestBody = {
      plattformID: platformID,
    };

    const path = "getLessonList";
    const method = "POST";

    getApiData<any[]>(`lesson_${platformID}`, path, method, requestBody)
      .then((data: any[] | null) => {
        if (data) {
          setIsLoading(false);
          setLessons(data);
        }
      })
      .catch((error: Error) => {});
  }, []);

  const handleCardClick = (lesson: LessonProps["lesson"]) => {
    setSelectedLesson(lesson);
    lesson.content = lesson.content.replace(/<br \/>/g, "\n");

    setExerciseActive(
      lesson.exercise !== "" ||
        lesson.correctAnswer !== "" ||
        lesson.language !== ""
    );
    setUnitTestsActive(lesson.language === "js" || lesson.language === "java"); // Enable unit tests toggle if Java is selected
  };
  const handleTitleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedLesson((prevLesson) => ({
      ...prevLesson!,
      title: e.target.value,
    }));
  };
  const handleIDChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setSelectedLesson((prevLesson) => ({
      ...prevLesson!,
      id: e.target.value,
    }));
  };
  const handleUnitTestChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setSelectedLesson((prevLesson) => ({
      ...prevLesson!,
      unitTest: e.target.value,
    }));
  };
  const handleContentChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setSelectedLesson((prevLesson) => ({
      ...prevLesson!,
      content: e.target.value,
    }));
  };
  const handleExerciseChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setSelectedLesson((prevLesson) => ({
      ...prevLesson!,
      exercise: e.target.value,
    }));
  };
  const handleCorrectAnswerChange = (
    e: React.ChangeEvent<HTMLTextAreaElement>
  ) => {
    setSelectedLesson((prevLesson) => ({
      ...prevLesson!,
      correctAnswer: e.target.value,
    }));
  };
  const handleDescriptionChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedLesson((prevLesson) => ({
      ...prevLesson!,
      description: e.target.value,
    }));
  };
  const handleLanguageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedLesson((prevLesson) => ({
      ...prevLesson!,
      language: e.target.id,
    }));

    // Enable unit tests toggle if Java is selected
    setUnitTestsActive(e.target.id === "java");
  };
  const handleCategoryChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedLesson((prevLesson) => ({
      ...prevLesson!,
      category: e.target.value,
    }));
  };
  const handleShowDisplaySolutionChange = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    setSelectedLesson((prevLesson) => ({
      ...prevLesson!,
      showDisplaySolution: e.target.checked,
    }));
  };
  const handlePlusCardClick = () => {
    const newLesson = {
      id: (lessons.length + 1).toString(),
      title: "",
      description: "",
      content: "",
      exercise: "",
      correctAnswer: "",
      language: "",
      category: "",
      unitTest: "", // Added unitTest property
      showDisplaySolution: false,
    };

    setSelectedLesson(newLesson);
  };
  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (selectedLesson) {
      try {
        if (!exerciseActive) {
          selectedLesson.exercise = "";
          selectedLesson.correctAnswer = "";
          selectedLesson.correctAnswer = "";
        }
        console.log(selectedLesson);
        selectedLesson.content = selectedLesson.content.replace(
          /\n/g,
          "<br />"
        );

        let updatedList = [...lessons]; // Eine Kopie der originalen lessons-Liste erstellen

        const lessonIndex = lessons.findIndex(
          (lesson) => lesson.id === selectedLesson.id
        ); // Überprüfen, ob selectedLesson bereits in der Liste ist

        if (lessonIndex !== -1) {
          // selectedLesson ist bereits in der Liste vorhanden, daher wird sie aktualisiert
          updatedList[lessonIndex] = selectedLesson;
        } else {
          updatedList.push(selectedLesson);
        }

        console.log(updatedList);
       
        setLessons(updatedList);


        const bodyRequest = {
          plattformID: platformID,
          lessonsList: updatedList,
        };
        console.log(bodyRequest);


        const response = await makeApiRequest<any>(
          "setLessonList",
          "POST",
          bodyRequest
        );

        if (response.ok) {
          const data = await response.json();
          setLessons(data);
          console.log("Daten erfolgreich aktualisiert:", data);
          if (sessionStorage.getItem("getLessonList") === "true") {
            sessionStorage.setItem(
              `lesson_${platformID}`,
              JSON.stringify(data)
            );
          }
        } else {
          console.log("Fehler beim Aktualisieren der Daten");
        }
      } catch (error) {
        console.log("Fehler beim Aktualisieren der Daten:", error);
      }
    }

    setSelectedLesson(null);
  };
  function shouldShowDisplaySolution() {
    const language = selectedLesson?.language;
    return (
      language === "html" ||
      language === "css" ||
      language === "htmlCss" ||
      language === "js" ||
      language === "PHP"
    );
  }
  function shouldShowDisplaySolutionFirstLesson() {
    const language = firstLesson?.language;

    return (
      language === "html" ||
      language === "css" ||
      language === "htmlCss" ||
      language === "js" ||
      language === "PHP"
    );
  }

  return (
    <div>
      <NavbarComponent disabled={false} />
      <div>
        <div style={{ marginTop: "5vw" }}>
          {/* Liste der Unterrichtseinheiten */}
          <div
            className="card-container"
            style={{
              position: "absolute",
              width: "18vw",
              display: "flex",
              flexWrap: "wrap",
            }}
          >
            {/* Neue Unterrichtseinheit hinzufügen */}
            <div
              className={`card`}
              style={{
                width: "17vw",
                height: "7vw",
                margin: "1rem",
                paddingTop: "0.5vw",
                paddingLeft: "0.5vw",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                cursor: "pointer",
              }}
              onClick={handlePlusCardClick}
            >
              <FaPlus size={48} />
            </div>

            {/* Liste der vorhandenen Unterrichtseinheiten */}
            {isLoading ? (
              <div>Laden...</div> // Anzeige während des Ladevorgangs
            ) : (
              lessons.map((lessonItem) => (
                <div
                  className={`card ${
                    selectedLesson?.id === lessonItem.id ? "selected" : ""
                  }`}
                  style={{
                    width: "17vw",
                    height: "7vw",
                    margin: "1rem",
                    paddingTop: "0.5vw",
                    paddingLeft: "0.5vw",
                  }}
                  key={lessonItem.id}
                  onClick={() => handleCardClick(lessonItem)}
                >
                  <Card.Title>{lessonItem.title}</Card.Title>
                  <i>{lessonItem.category}</i>
                </div>
              ))
            )}
          </div>

          {/* Formular zum Bearbeiten der Unterrichtseinheit */}
          <div
            className="form-container"
            style={{
              position: "fixed",
              left: "57%",
              transform: "translateX(-50%)",
              width: "75vw",
              margin: "1rem auto",
              height: "40vw",
              overflow: "auto",
            }}
          >
            {selectedLesson ? (
              <Card
                style={{
                  position: "fixed",
                  left: "57%",
                  transform: "translateX(-50%)",
                  width: "75vw",
                  margin: "1rem auto",
                }}
              >
                <Card.Body>
                  <Form onSubmit={handleSubmit}>
                    <Form.Group controlId="lessonID">
                      <div style={{ display: "flex", alignItems: "center" }}>
                        <Form.Label
                          style={{
                            alignItems: "center",
                            width: "10vw",
                            marginRight: "1rem",
                          }}
                        >
                          ID
                        </Form.Label>
                        <Form.Control
                          type="text"
                          value={selectedLesson.id}
                          onChange={handleIDChange}
                          style={{ width: "65vw" }}
                          required
                        />
                      </div>
                    </Form.Group>
                    <Form.Group controlId="lessonTitle">
                      <div style={{ display: "flex", alignItems: "center" }}>
                        <Form.Label
                          style={{
                            alignItems: "center",
                            width: "10vw",
                            marginRight: "1rem",
                          }}
                        >
                          Titel
                        </Form.Label>
                        <Form.Control
                          type="text"
                          value={selectedLesson.title}
                          onChange={handleTitleChange}
                          style={{ width: "65vw" }}
                          required
                        />
                      </div>
                    </Form.Group>
                    <Form.Group controlId="lessonDescription">
                      <div style={{ display: "flex", alignItems: "center" }}>
                        <Form.Label
                          style={{ width: "10vw", marginRight: "1rem" }}
                        >
                          Beschreibung
                        </Form.Label>
                        <Form.Control
                          type="text"
                          value={selectedLesson.description}
                          onChange={handleDescriptionChange}
                          style={{ width: "65vw" }}
                          required
                        />
                      </div>
                    </Form.Group>
                    <br />
                    <Form.Group controlId="lessonContent">
                      <Form.Label>Inhalt der Unterrichtseinheit</Form.Label>
                      <Form.Control
                        as="textarea"
                        rows={3}
                        value={selectedLesson.content}
                        onChange={handleContentChange}
                        required
                      />
                    </Form.Group>
                    <br />
                    <Form.Group controlId="lessonCategory">
                      <Form.Label>Kategorie</Form.Label>
                      <Form.Control
                        type="text"
                        value={selectedLesson.category}
                        onChange={handleCategoryChange}
                        required
                      />
                    </Form.Group>
                    <br />
                    {/* Übung aktivieren/deaktivieren */}
                    <Form.Group controlId="exerciseToggle">
                      <div style={{ display: "flex", alignItems: "center" }}>
                        <Form.Check
                          type="switch"
                          id="exerciseSwitch"
                          label="Übung aktivieren"
                          checked={exerciseActive}
                          onChange={handleExerciseToggle}
                        />
                      </div>
                    </Form.Group>
                    <br />
                    {/* Übungsinhalte anzeigen, wenn Übung aktiviert ist */}
                    {exerciseActive && (
                      <>
                        <Form.Group controlId="lessonExercise">
                          <Form.Label>Aufgabentext</Form.Label>
                          <Form.Control
                            as="textarea"
                            rows={2}
                            value={selectedLesson.exercise}
                            onChange={handleExerciseChange}
                          />
                        </Form.Group>
                        <Form.Group controlId="lessonCorrectAnswer">
                          <Form.Label>Lösung</Form.Label>
                          <Form.Control
                            as="textarea"
                            rows={3}
                            value={selectedLesson.correctAnswer}
                            onChange={handleCorrectAnswerChange}
                          />
                        </Form.Group>
                        <br />
                        <Form.Group
                          controlId="showDisplaySolution"
                          style={{
                            display: shouldShowDisplaySolution()
                              ? "block"
                              : "none",
                          }}
                        >
                          <div
                            style={{ display: "flex", alignItems: "center" }}
                          >
                            <Form.Check
                              type="switch"
                              id="displaySolutionSwitch"
                              label="Lösungsvorschau anzeigen"
                              checked={selectedLesson.showDisplaySolution}
                              onChange={handleShowDisplaySolutionChange}
                            />
                          </div>
                        </Form.Group>
                        <br />
                        <Form.Group controlId="lessonLanguage">
                          <Form.Label>Programmiersprache</Form.Label>
                          {Object.keys(languageOptions).map((key) => (
                            <Form.Check
                              key={key}
                              type="radio"
                              name="language"
                              id={languageOptions[key]}
                              label={key}
                              checked={
                                selectedLesson.language === languageOptions[key]
                              }
                              onChange={handleLanguageChange}
                            />
                          ))}
                          <Form.Check
                            type="radio"
                            name="language"
                            id="other"
                            label="Andere Sprachen"
                            checked={
                              !Object.values(languageOptions).includes(
                                selectedLesson.language
                              )
                            }
                            onChange={handleLanguageChange}
                          />
                        </Form.Group>
                        {unitTestsActive && ( // Show unit tests textarea only if unit tests toggle is active
                          <Form.Group controlId="unitTestsInput">
                            <Form.Label>Unit Tests</Form.Label>
                            <Form.Control
                              as="textarea"
                              rows={3}
                              value={selectedLesson.unitTest}
                              onChange={handleUnitTestChange}
                            />
                          </Form.Group>
                        )}
                      </>
                    )}
                    <br />
                    <Button variant="success" type="submit">
                      <AiOutlineSave size={36} color="white" />
                    </Button>
                    <br /> <br /> <br /> <br /> <br /> <br />
                  </Form>
                </Card.Body>
              </Card>
            ) : (
              <Card
                style={{
                  position: "fixed",
                  left: "57%",
                  transform: "translateX(-50%)",
                  width: "75vw",
                  margin: "1rem auto",
                }}
              >
                <Card.Body>
                  <Form onSubmit={handleSubmit}>
                    <Form.Group controlId="lessonID">
                      <div style={{ display: "flex", alignItems: "center" }}>
                        <Form.Label
                          style={{
                            alignItems: "center",
                            width: "10vw",
                            marginRight: "1rem",
                          }}
                        >
                          ID
                        </Form.Label>
                        <Form.Control
                          type="text"
                          value={firstLesson.id}
                          onChange={handleIDChange}
                          style={{ width: "65vw" }}
                          required
                        />
                      </div>
                    </Form.Group>
                    <Form.Group controlId="lessonTitle">
                      <div style={{ display: "flex", alignItems: "center" }}>
                        <Form.Label
                          style={{
                            alignItems: "center",
                            width: "10vw",
                            marginRight: "1rem",
                          }}
                        >
                          Titel
                        </Form.Label>
                        <Form.Control
                          type="text"
                          value={firstLesson.title}
                          onChange={handleTitleChange}
                          style={{ width: "65vw" }}
                          required
                        />
                      </div>
                    </Form.Group>
                    <Form.Group controlId="lessonDescription">
                      <div style={{ display: "flex", alignItems: "center" }}>
                        <Form.Label
                          style={{ width: "10vw", marginRight: "1rem" }}
                        >
                          Beschreibung
                        </Form.Label>
                        <Form.Control
                          type="text"
                          value={firstLesson.description}
                          onChange={handleDescriptionChange}
                          style={{ width: "65vw" }}
                          required
                        />
                      </div>
                    </Form.Group>
                    <br />
                    <Form.Group controlId="lessonContent">
                      <Form.Label>Inhalt der Unterrichtseinheit</Form.Label>
                      <Form.Control
                        as="textarea"
                        rows={3}
                        value={firstLesson.content}
                        onChange={handleContentChange}
                        required
                      />
                    </Form.Group>
                    <br />
                    <Form.Group controlId="lessonCategory">
                      <Form.Label>Kategorie</Form.Label>
                      <Form.Control
                        type="text"
                        value={firstLesson.category}
                        onChange={handleCategoryChange}
                        required
                      />
                    </Form.Group>
                    <br />
                    {/* Übung aktivieren/deaktivieren */}
                    <Form.Group controlId="exerciseToggle">
                      <div style={{ display: "flex", alignItems: "center" }}>
                        <Form.Check
                          type="switch"
                          id="exerciseSwitch"
                          label="Übung aktivieren"
                          checked={exerciseActive}
                          onChange={handleExerciseToggle}
                        />
                      </div>
                    </Form.Group>
                    <br />
                    {/* Übungsinhalte anzeigen, wenn Übung aktiviert ist */}
                    {exerciseActive && (
                      <>
                        <Form.Group controlId="lessonExercise">
                          <Form.Label>Aufgabentext</Form.Label>
                          <Form.Control
                            as="textarea"
                            rows={2}
                            value={firstLesson.exercise}
                            onChange={handleExerciseChange}
                          />
                        </Form.Group>
                        <Form.Group controlId="lessonCorrectAnswer">
                          <Form.Label>Lösung</Form.Label>
                          <Form.Control
                            as="textarea"
                            rows={3}
                            value={firstLesson.correctAnswer}
                            onChange={handleCorrectAnswerChange}
                          />
                        </Form.Group>
                        <br />
                        <Form.Group
                          controlId="showDisplaySolution"
                          style={{
                            display: shouldShowDisplaySolutionFirstLesson()
                              ? "block"
                              : "none",
                          }}
                        >
                          <div
                            style={{ display: "flex", alignItems: "center" }}
                          >
                            <Form.Check
                              type="switch"
                              id="displaySolutionSwitch"
                              label="Lösungsvorschau anzeigen (Nur sinnvoll bei HTML Code oder HTML Code mit korrekt eingebettetem JavaScript/CSS Code in HTML)"
                              checked={firstLesson.showDisplaySolution}
                              onChange={handleShowDisplaySolutionChange}
                            />
                          </div>
                        </Form.Group>
                        <br />
                        <Form.Group controlId="lessonLanguage">
                          <Form.Label>Programmiersprache</Form.Label>
                          {Object.keys(languageOptions).map((key) => (
                            <Form.Check
                              key={key}
                              type="radio"
                              name="language"
                              id={languageOptions[key]}
                              label={key}
                              checked={
                                firstLesson.language === languageOptions[key]
                              }
                              onChange={handleLanguageChange}
                            />
                          ))}
                          <Form.Check
                            type="radio"
                            name="language"
                            id="other"
                            label="Andere Sprachen"
                            checked={
                              !Object.values(languageOptions).includes(
                                firstLesson.language
                              )
                            }
                            onChange={handleLanguageChange}
                          />
                        </Form.Group>
                        {unitTestsActive && ( // Show unit tests textarea only if unit tests toggle is active
                          <Form.Group controlId="unitTestsInput">
                            <Form.Label>Unit Tests</Form.Label>
                            <Form.Control
                              as="textarea"
                              rows={3}
                              value={firstLesson.unitTest}
                              onChange={handleUnitTestChange}
                            />
                          </Form.Group>
                        )}
                      </>
                    )}
                    <br />
                    <Button variant="success" type="submit">
                      <AiOutlineSave size={36} color="white" />
                    </Button>
                    <br /> <br /> <br /> <br /> <br /> <br />
                  </Form>
                </Card.Body>
              </Card>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Lesson;
