import Dashboard from './modules/dashboard/dashboard';
import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Routes, useLocation, Route, useNavigate } from 'react-router-dom';
import { Container } from 'react-bootstrap';
import Lesson from './modules/lessons/lessonDetail';
import PlattformsOverview from './modules/lessons/plattformOverview';
import FooterComponent from './base/footerComponent';
import { website_title } from './modules/app_configuration/app_texts';
import PlatformDetailsForm from './modules/lessons/plattformDetail';
import PlatformUserForm from './modules/lessons/plattformUsers';
import Registration from './modules/login/registerDialog';

import Impressum from './modules/legal/impressum';
import Datenschutz from './modules/legal/datenschutz';
import CookieConsentBanner from './modules/legal/cookieConsentBanner';
import PasswordDialog from './modules/login/passwordDialog';

export interface PasswordRedirectProps {
  passwordEntered: boolean;
}

const PasswordRedirect: React.FC<PasswordRedirectProps> = ({ passwordEntered }) => {
  const navigate = useNavigate();
  const currentLocation = useLocation();

  useEffect(() => {
    if (
      !passwordEntered &&
      currentLocation.pathname !== '/password' &&
      currentLocation.pathname !== '/impressum' &&
      currentLocation.pathname !== '/datenschutz' &&
      currentLocation.pathname !== '/registrierung'
    ) {
      navigate('/password'); // Redirect to password page
    }
  }, [passwordEntered, navigate]);

  return null;
};

const App: React.FC = () => {
  const [showDashboard, setShowDashboard] = useState(false);
  const [passwordEntered, setPasswordEntered] = useState(false);

  const handlePasswordEntered = () => {
    setPasswordEntered(true);
  };

  const hidePasswordDialog = () => {
    setPasswordEntered(true);
  };

  useEffect(() => {
    document.title = website_title; // Set the website title dynamically
  }, []);

  return (
    <Router>
      <div style={{ position: 'relative', minHeight: '100vh' }}>
        <PasswordRedirect passwordEntered={passwordEntered} />
        <Container className="custom-container" style={{ paddingBottom: '80px' }}>
          <main>
            <Routes>
              <Route path="/plattformsOverview" element={<PlattformsOverview />} />
              <Route path="/impressum" element={<Impressum passwordEntered={passwordEntered} />} />
              <Route path="/datenschutz" element={<Datenschutz passwordEntered={passwordEntered} />} />
              <Route path="/registrierung" element={<Registration passwordEntered={passwordEntered} />} />

              <Route
                path="/password"
                element={<PasswordDialog onPasswordEntered={handlePasswordEntered} />}
              />
              <Route path="/:plattformID" element={<Lesson />} />
              <Route path="platformDetails/:plattformID" element={<PlatformDetailsForm />} />
              <Route path="plattformUsers/:plattformID" element={<PlatformUserForm />} />

            </Routes>
          </main>
        </Container>
        <CookieConsentBanner />
        <FooterComponent />
      </div>
    </Router>
  );
};

export default App;
