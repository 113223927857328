import React, { useState, useEffect } from "react";

import { Modal, Button, Form } from "react-bootstrap";
import "./cookieConsentBanner.css";

const CookieConsentBanner: React.FC = () => {
  const [isCookieAccepted, setIsCookieAccepted] = useState<boolean>(false);
  const [isPopupVisible, setIsPopupVisible] = useState<boolean>(true);
  const [isPlattformOverviewEnabled, setIsPlattformOverviewEnabled] =
    useState<boolean>(false);
  const [isPlattformDetailsEnabled, setIsPlattformDetailsEnabled] =
    useState<boolean>(false);
  const [isAccountsEnabled, setIsAccountsEnabled] = useState<boolean>(false);
  const [isLessonUnitsEnabled, setIsLessonUnitsEnabled] =
    useState<boolean>(false);
  const [isDataProcessing, setIsDataProcessing] = useState<boolean>(false);
  const [isGerman, setIsGerman] = useState<boolean>(false);
  const [isBannerAllowed, setIsBannerAllowed] = useState<boolean>(true);

  useEffect(() => {
    const browserLanguage = navigator.language.toLowerCase();
    setIsGerman(browserLanguage.startsWith("de"));
    const currentPagePath = window.location.pathname;
    if (
      currentPagePath.includes("/impressum") ||
      currentPagePath.includes("/datenschutz")
    ) {
      setIsBannerAllowed(false);
    }
  }, []);

  const onAccept = () => {
    setIsCookieAccepted(true);
    setIsPopupVisible(false);
    sessionStorage.setItem("getPlattformDetails", "true");
    sessionStorage.setItem("getLessonList", "true");
    sessionStorage.setItem("getPlattformStudentUsers", "true");
    sessionStorage.setItem("isDataProcessing", "true");
    sessionStorage.setItem("getUserPlattforms", "true");


  };

  const onDeclineAll = () => {
    sessionStorage.removeItem("isDataProcessing");
    sessionStorage.removeItem("getLessonList");
    sessionStorage.removeItem("getPlattformDetails");
    sessionStorage.removeItem("getPlattformStudentUsers");
    sessionStorage.removeItem("getUserPlattforms");


    setIsPopupVisible(false);
    setIsCookieAccepted(true);
  };

  const onSaveSettings = () => {
    setIsPopupVisible(false);
    onDeclineAll();

    if (isPlattformOverviewEnabled) {
      sessionStorage.setItem(
        "getUserPlattforms",
        String(isPlattformOverviewEnabled)
      );
    }
    if (isLessonUnitsEnabled) {
      sessionStorage.setItem(
        "getLessonList",
        String(isLessonUnitsEnabled)
      );
    }
    if (isPlattformDetailsEnabled) {
      sessionStorage.setItem(
        "getPlattformDetails",
        String(isPlattformDetailsEnabled)
      );
    }
    if (isAccountsEnabled) {
      sessionStorage.setItem(
        "getPlattformStudentUsers",
        String(isAccountsEnabled)
      );
    }
    if (isDataProcessing) {
      sessionStorage.setItem("isDataProcessing", String(isDataProcessing));
    }
    if (
      isDataProcessing ||
      isPlattformOverviewEnabled ||
      isPlattformDetailsEnabled ||
      isAccountsEnabled
    ) {
      setIsCookieAccepted(true);
    } else {
      setIsCookieAccepted(true);
      onDeclineAll();
    }
  };

  const onCustomizeSettings = () => {
    setIsPopupVisible(false);
  };

  if (isCookieAccepted) {
    return null;
  }
  return (
    <>
      {isBannerAllowed && (
        <div>
          {isPopupVisible && (
            <Modal show={true} centered>
              <Modal.Header>
                <Modal.Title>
                  {isGerman ? "Lokale Daten Speicherung" : "Local Data Storage"}
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <div className="cookie-overlay-content">
                  <div className="cookie-overlay-text">
                    {isGerman ? (
                      <>
                        Diese Webseite speichert verschiedene Daten im Session
                        Storage zur Ladezeitoptimierung, speichert im Session
                        Storage (Lokaler Speicher in ihrem Browser während
                        dieser Sitzung) ihr Einverständis hierzu sowie
                        verarbeitet und speichert Daten in einem Microsoft Azure
                        Backend mit einer Azure SQL Datenbank.
                        <br /> Cookies, LocalStorage (Browser Speicher
                        unabhängig von der Sitzung) und der Cache Speicher
                        werden nicht verwendet.
                        <br />
                        Falls sie ihre Meinung ändern, können sie die Seite neu
                        laden und alles neu einstellen.
                        <br /> <br />
                        Daten welche sie hier eingeben werden stehts durch
                        dieses Microsoft Azure Backend verarbeitet und
                        gegebenenfalls dort gespeichert. Diese (SQL-) Server
                        stehen in Europa und verarbeiten die Daten nach der
                        DSVGO. <br /> Klicken Sie auf "Einstellungen anpassen",
                        um einzeln auszuwählen, was lokal gespeichert wird, oder
                        auf "Alle ablehnen", falls Sie damit nicht einverstanden
                        sind. <br /> Allerdings falls die alles ablehnen
                        funktioniert der Login beispielsweise nicht mehr.
                        <br /> Außerdem sollten sie wissen das wir keine Daten
                        verkaufen welche wir speichern oder verarbeiten. <br />
                        Weitere Informationen finden sie auf unserer Datenschutz
                        Webseite.
                        <a href="https://www.lernplattform-creator.de/datenschutz">
                          Datenschutz
                        </a>
                        <br />
                        Über uns finden sie im Impressmu weitere Informationen.
                        <a href="https://www.lernplattform-creator.de/impressum">
                          Impressum
                        </a>{" "}
                        <br />
                      </>
                    ) : (
                      <>
                        This website stores various data in session storage for
                        loading optimization, stores your consent in session
                        storage (local storage in your browser during this
                        session), and processes and stores data in a Microsoft
                        Azure backend with an Azure SQL database.
                        <br /> Cookies, local storage (browser storage
                        independent of the session), and cache storage are not
                        used.
                        <br />
                        If you change your mind, you can reload the page and
                        reset everything.
                        <br /> <br />
                        The data you enter here is always processed and
                        potentially stored by this Microsoft Azure backend.
                        These (SQL) servers are located in Europe. <br /> Click
                        on "Customize Settings" to individually select what is
                        stored locally, or click "Decline All" if you do not
                        agree. <br /> However, if you decline everything,
                        certain functionalities such as login will no longer
                        work.
                        <br /> Also, please be aware that we do not sell any
                        data we store or process. <br />
                        For more information, please visit our privacy policy
                        page.
                        <a href="https://www.lernplattform-creator.de/datenschutz">
                          Privacy Policy
                        </a>{" "}
                        <br />
                        For additional information about us, please refer to our
                        impressum page.
                        <a href="https://www.lernplattform-creator.de/impressum">
                          Impressum
                        </a>{" "}
                        <br />
                      </>
                    )}
                  </div>

                  <div className="button-group">
                    <Button
                      variant="primary"
                      onClick={onAccept}
                      style={{ margin: "0 0.5vw" }}
                    >
                      {isGerman ? "Alle akzeptieren" : "Accept All"}
                    </Button>
                    <Button
                      variant="outline-danger"
                      onClick={onDeclineAll}
                      style={{ margin: "0 0.5vw" }}
                    >
                      {isGerman ? "Alle ablehnen" : "Decline All"}
                    </Button>
                    <Button
                      variant="outline-primary"
                      onClick={onCustomizeSettings}
                      style={{ margin: "0 0.5vw" }}
                    >
                      {isGerman
                        ? "Einstellungen anpassen"
                        : "Customize Settings"}
                    </Button>
                  </div>
                </div>
              </Modal.Body>
            </Modal>
          )}
          {!isPopupVisible && (
            <Modal show={true} centered>
              <Modal.Header>
                <Modal.Title>
                  {isGerman
                    ? "Speicher/Verarbeitungs-Einstellungen anpassen"
                    : "Adjust Storage/Processing Settings"}
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <div className="cookie-overlay-text">
                  {isGerman ? (
                    <>
                      Berücksichtigen Sie, dass die Zustimmung einer der Punkte
                      bedeutet, dass diese Zustimmung auch immer im Session
                      Storage gespeichert werden muss. <br />
                      Falls sie der Datenverarbeitung nicht zustimmen
                      funktioniert diese Seite nicht mehr, da beispielsweise für
                      den Login die Login Daten übertragen werden müssen. <br />
                      Falls sie das Caching im Session Storage deaktivieren kann
                      es zu deutlich erhöhten Ladezeiten führen und einer
                      schlechten Perfomance der Seite. <br />
                      Wir verwenden das Caching im Session Storage, da so die
                      Daten direkt gelöscht werden bei dem Schließen der Seite.
                      (Daten im Cache bleiben teilweise Monate lang gespeichert)
                    </>
                  ) : (
                    <>
                      Take into account that consenting to one of the items
                      means that this consent must also always be stored in the
                      session storage. <br />
                      If you do not agree to the data processing, this site will
                      no longer function, as login data, for example, needs to
                      be transmitted. <br />
                      Disabling caching in session storage can result in
                      significantly increased loading times and poor site
                      performance. <br />
                      We use caching in session storage to ensure that data is
                      deleted directly when the page is closed. (Data in the
                      cache can remain stored for months)
                    </>
                  )}
                </div>
                <Form>
                  <Form.Group controlId="formProgressSaving">
                    <Form.Check
                      type="checkbox"
                      label={isGerman ? "Datenverarbeitung" : "Data Processing"}
                      checked={isDataProcessing}
                      onChange={() =>
                        setIsDataProcessing((prevState) => !prevState)
                      }
                    />
                  </Form.Group>
                  <Form.Group controlId="formPlattformOverviewSaving">
                    <Form.Check
                      type="checkbox"
                      label={
                        isGerman
                          ? "Caching im Session Storage: Plattform Übersicht"
                          : "Caching in Session Storage: Platform Overview"
                      }
                      checked={isPlattformOverviewEnabled}
                      onChange={() =>
                        setIsPlattformOverviewEnabled((prevState) => !prevState)
                      }
                    />
                  </Form.Group>
                  <Form.Group controlId="formPlattformDetailsSaving">
                    <Form.Check
                      type="checkbox"
                      label={
                        isGerman
                          ? "Caching im Session Storage: Plattform Einstellungen"
                          : "Caching in Session Storage: Platform Settings"
                      }
                      checked={isPlattformDetailsEnabled}
                      onChange={() =>
                        setIsPlattformDetailsEnabled((prevState) => !prevState)
                      }
                    />
                  </Form.Group>
                  <Form.Group controlId="formAccountsSaving">
                    <Form.Check
                      type="checkbox"
                      label={
                        isGerman
                          ? "Caching im Session Storage: Accounts"
                          : "Caching in Session Storage: Accounts"
                      }
                      checked={isAccountsEnabled}
                      onChange={() =>
                        setIsAccountsEnabled((prevState) => !prevState)
                      }
                    />
                  </Form.Group>
                  <Form.Group controlId="formLessonUnitsSaving">
                    <Form.Check
                      type="checkbox"
                      label={
                        isGerman
                          ? "Caching im Session Storage: Unterrichtseinheiten"
                          : "Caching in Session Storage: Lesson Units"
                      }
                      checked={isLessonUnitsEnabled}
                      onChange={() =>
                        setIsLessonUnitsEnabled((prevState) => !prevState)
                      }
                    />
                  </Form.Group>
                </Form>
              </Modal.Body>
              <Modal.Footer>
                <Button
                  variant="secondary"
                  onClick={() => setIsPopupVisible(true)}
                >
                  {isGerman ? "Abbrechen" : "Cancel"}
                </Button>
                <Button variant="primary" onClick={onSaveSettings}>
                  {isGerman ? "Speichern" : "Save"}
                </Button>
              </Modal.Footer>
            </Modal>
          )}
        </div>
      )}
    </>
  );
};
export default CookieConsentBanner;
