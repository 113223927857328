import { urlAPI } from "./config";

export async function getApiData<T = any>(
  key: string,
  path: string,
  method: string,
  requestBody: any
): Promise<T | null> {
  try {
    if (sessionStorage.getItem(path) === "true") {
      const cachedData = sessionStorage.getItem(key);
      if (cachedData && Object.keys(cachedData).length > 0) {
        console.log("cached Data found!");
        return JSON.parse(cachedData);
      }
    } else {
      console.log("cached data is deactivated!");
    }
    console.log("no cached data found");
    const data = await makeApiRequest<T>(path, method, requestBody);
    if (sessionStorage.getItem(path) === "true") {
      sessionStorage.setItem(key, JSON.stringify(data));
    }
    return data;
  } catch (error) {
    throw error;
  }
}

export async function makeApiRequest<T = any>(
  path: string,
  method: string,
  requestBody: any
): Promise<T> {
  try {
    const url = `${urlAPI}${path}`;
    const authToken = sessionStorage.getItem("authToken");
    requestBody= sanitizeInput(requestBody);

    const headers: Record<string, string> = {
      "Content-Type": "application/json",
    };

    if (authToken) {
      if (method === "GET") {
        headers["authorization"] = `Bearer ${authToken}`;
      } else {
        const updatedRequestBody = { ...requestBody, authorization: authToken };
        requestBody = updatedRequestBody;
      }
    }
    console.log("API Request:");
    console.log(method);
    console.log(headers);
    console.log(requestBody);

    const response = await fetch(url, {
      method: method,
      headers: headers,
      body: JSON.stringify(requestBody),
    });

    console.log("Response:");
    console.log(response.status);
    console.log(response.ok);

    let responseData;
    try {
      responseData = await response.json();
      console.log(responseData);

      if (!response.ok) {
        console.log("API Request failed");
        throw new Error("API request failed");
      }
    } catch (error) {
      responseData = await response;
      console.log(responseData);
    }

    return responseData;
  } catch (error) {
    throw error;
  }
}

function sanitizeInput(input: any): any {
  // Wenn der Eingabewert ein Objekt ist (aber kein Array oder null)
  if (typeof input === "object" && input !== null && !Array.isArray(input)) {
    let sanitizedObj: any = {};
    for (let key in input) {
      sanitizedObj[key] = sanitizeInput(input[key]);
    }
    return sanitizedObj;
  } 

  // Wenn der Eingabewert ein Array ist
  else if (Array.isArray(input)) {
    return input.map(item => sanitizeInput(item));
  } 
  
  // Ansonsten behandeln wir den Eingabewert als String oder primitiven Wert
  else {
    let str = String(input);
    // Grundlegende Neutralisierung von SQL-Injektionssymbolen
    str = str.replace(/;/g, "\\;");
    str = str.replace(/--/g, "\\--");
    str = str.replace(/'/g, "\\'");
    str = str.replace(/"/g, '\\"');

    // Neutralisieren von gängigen SQL-Befehlen
    str = str.replace(/DROP\s+TABLE/gi, "DROP_TABLE");
    str = str.replace(/INSERT\s+INTO/gi, "INSERT_INTO");
    str = str.replace(/UPDATE\s+/gi, "UPDATE_");
    str = str.replace(/DELETE\s+FROM/gi, "DELETE_FROM");
    str = str.replace(/SELECT\s+/gi, "SELECT_");
    str = str.replace(/WHERE\s+/gi, "WHERE_");
    
    // Entfernen von eventuell Schädlichen Zeichen
    const disallowedInputRegex = /[^]a-zA-Z0-9_!@#$%^&*()+=[{}|\\:;"'<>,.? \t\n\r\u00A0-\u024F-]/g;
    str = str.replace(disallowedInputRegex, " ");
    str = str.replace(/&/g, "&amp;");
    
    return str;
  }
}
