import React, { useState, useEffect } from "react";
import { Card, Table, Button, Form } from "react-bootstrap";
import { getApiData, makeApiRequest } from "../network/network_handler";
import NavbarComponent from "../../base/navbarComponent";
import { MdArrowBack } from "react-icons/md";
import { useNavigate } from "react-router-dom";

interface StudentUser {
  id: string;
  idToken: string;
  userName: string;
  password: string;
  description: string;
  licenseDuration: string;
}

const PlatformStudentUsers: React.FC = () => {
  const url = new URL(window.location.href);
  const platformID = url.pathname.split("/")[2];
  const navigate = useNavigate();

  const [studentUsers, setStudentUsers] = useState<StudentUser[]>([]);

  useEffect(() => {
    const path = "getPlattformStudentUsers";
    const method = "POST";
    const requestBody = {
      plattformID: platformID,
    };
    getApiData<StudentUser[]>(
      `plattformStudentUsers_${platformID}`,
      path,
      method,
      requestBody
    )
      .then((data: StudentUser[] | null) => {
        if (data) {
          setStudentUsers(data);
        }
      })
      .catch((error: Error) => {
        alert("Fehler beim Abrufen der Studentenbenutzer");
      });
  }, []);

  const handleSaveUser = async (userId: string) => {
    const user = studentUsers.find((user) => user.id === userId);
    if (!user) return;

    sendUserArray();
  };

  const sendUserArray = async () => {
    try {
      const bodyRequest = {
        plattformID: platformID,
        siteStudentAccounts: studentUsers,
      };
      const response = await makeApiRequest<any>(
        "setPlattformUsers",
        "POST",
        bodyRequest
      );
      console.log(response);
      if (sessionStorage.getItem("getPlattformUsers") === "true") {
        sessionStorage.setItem(
          `plattformStudentUsers_${platformID}`,
          JSON.stringify(studentUsers)
        );
      }
      alert("Benutzer erfolgreich gespeichert");
    } catch (error) {
      console.log("Fehler beim Speichern:", error);
      alert("Fehler beim Speichern");
    }
  };

  const handleAddUser = () => {
    const newUser: StudentUser = {
      id: (studentUsers.length + 1).toString(),
      idToken: "generateIdToken()",
      userName: "",
      password: "",
      description: "",
      licenseDuration: "",
    };

    setStudentUsers((prevStudentUsers) => [...prevStudentUsers, newUser]);
    sendUserArray();
  };

  const handleDeleteUser = (userId: string) => {
    setStudentUsers((prevStudentUsers) =>
      prevStudentUsers.filter((user) => user.id !== userId)
    );
    sendUserArray();
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value, dataset } = e.target;
    const userId = dataset.userId;

    setStudentUsers((prevStudentUsers) =>
      prevStudentUsers.map((user) =>
        user.id === userId ? { ...user, [name]: value } : user
      )
    );
  };

  return (
    <div>
      <NavbarComponent disabled={false} />
      <div className="lesson-overview-container after-login-container">
        <Card style={{ width: "80vw", marginBottom: "2vw" }}>
          <Card.Header as="h2">
            <MdArrowBack
              style={{ cursor: "pointer" }}
              onClick={() => navigate(-1)}
            />
            Benutzer verwalten
          </Card.Header>
          <Card.Body>
            <br />
            <Table striped bordered hover>
              <thead>
                <tr>
                  <th>ID</th>
                  <th>Benutzername</th>
                  <th>Passwort</th>
                  <th>Beschreibung</th>
                  <th>Lizenzdauer</th>
                  <th>Aktion</th>
                </tr>
              </thead>
              <tbody>
                {studentUsers.map((user) => (
                  <tr key={user.id}>
                    <td>{user.id}</td>
                    <td>
                      <Form.Control
                        type="text"
                        name="userName"
                        value={user.userName}
                        data-user-id={user.id}
                        onChange={handleChange}
                      />
                    </td>
                    <td>
                      <Form.Control
                        type="text"
                        name="password"
                        value={user.password}
                        data-user-id={user.id}
                        onChange={handleChange}
                      />
                    </td>
                    <td>
                      <Form.Control
                        type="text"
                        name="description"
                        value={user.description}
                        data-user-id={user.id}
                        onChange={handleChange}
                      />
                    </td>
                    <td>
                      <Form.Control
                        type="text"
                        name="licenseDuration"
                        value={user.licenseDuration}
                        data-user-id={user.id}
                        onChange={handleChange}
                      />
                    </td>
                    <td>
                      <Button
                        variant="primary"
                        onClick={() => handleSaveUser(user.id)}
                      >
                        Speichern
                      </Button>
                      <br /> <br />
                      <Button
                        variant="danger"
                        onClick={() => handleDeleteUser(user.id)}
                      >
                        Löschen
                      </Button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
            <Button variant="primary" onClick={handleAddUser}>
              Benutzer hinzufügen
            </Button>
          </Card.Body>
        </Card>
      </div>
    </div>
  );
};

export default PlatformStudentUsers;
