import React, { useState, useEffect, useRef } from "react";
import { Card, Button, Form, Carousel } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import FooterComponent from "../../base/footerComponent";
import "./passwordDialog.css";
import screen1 from "./img/screen1.jpg";
import screen2 from "./img/screen2.jpg";
import screen3 from "./img/screen3.jpg";
import screen4 from "./img/screen4.jpg";
import screen5 from "./img/screen5.jpg";
import screen6 from "./img/screen6.jpg";
import screen7 from "./img/screen7.jpg";
import screen8 from "./img/screen8.jpg";

import NavbarComponent from "../../base/navbarComponent";
import {
  FaCode,
  FaGraduationCap,
  FaChartLine,
  FaDatabase,
  FaBookOpen,
  FaClock,
} from "react-icons/fa";

import { signIn } from "./auth"; // Passe den Pfad zur auth.tsx-Datei entsprechend an
import {
  welcome_headline,
  welcome_description,
} from "../app_configuration/app_texts";
import {
  withoutUserLoginEnable,
  withoutUserLoginName,
  withoutUserLoginPW,
} from "../app_configuration/app_settings";

interface UserAccount {
  id: string;
  idToken: string;
  userName: string;
  password: string;
  description: string;
  licenseDuration: string;
}

interface PasswordDialogProps {
  onPasswordEntered: () => void;
}

const PasswordDialog: React.FC<PasswordDialogProps> = ({
  onPasswordEntered,
}) => {
  const [wrongAttempts, setWrongAttempts] = useState(0);
  const [remainingTime, setRemainingTime] = useState(0);
  const [isDialogVisible, setIsDialogVisible] = useState(true);
  const navigate = useNavigate();
  const usernameRef = useRef<HTMLInputElement>(null);
  const passwordRef = useRef<HTMLInputElement>(null);
  const formRef = useRef<HTMLFormElement>(null);
  const loginButtonRef = useRef<HTMLButtonElement>(null);

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const { username, password } = event.currentTarget;
    handleLogin(username.value, password.value);
  };

  const handleWithoutLogin = () => {
    handleLogin(withoutUserLoginName, withoutUserLoginPW);
  };

  const handleLogin = (username: string, password: string) => {
    if (sessionStorage.getItem("isDataProcessing") !== "true") {
      alert("Login nicht möglich ohne das ihre Daten verarbeitet werden!");
    } else {
      signIn(username, password)
        .then((response) => {
          console.log(response);
          try {
            console.log(response.token);
            sessionStorage.setItem("authToken", response.token);
          } catch (error) {
            console.error(error);
          }

          if (response.token) {
            onPasswordEntered();
            navigate("/PlattformsOverview");
          } else {
            handleWrongAttempt();
          }
        })
        .catch((error) => {
          console.error(error);
          handleWrongAttempt();
        });
    }
  };

  const linkStyle = {
    color: 'blue',
    textDecoration: 'underline',
    padding: '0 5px',
  };

  const handleWrongAttempt = () => {
    setWrongAttempts((prevAttempts) => prevAttempts + 1);
    const MAX_ATTEMPTS = 6;
    const LOCKOUT_DURATION = 60 * 1000;

    if (wrongAttempts + 1 >= MAX_ATTEMPTS) {
      setRemainingTime(LOCKOUT_DURATION);
      setIsDialogVisible(false);
      setTimeout(() => {
        setIsDialogVisible(true);
        setWrongAttempts(0);
      }, LOCKOUT_DURATION);
    }
    alert("Falscher Benutzername oder Passwort oder ihr Account ist gesperrt!");
  };

  useEffect(() => {
    let timer: NodeJS.Timeout;

    if (remainingTime > 0) {
      timer = setInterval(() => {
        setRemainingTime((prevTime) => prevTime - 1000);
      }, 1000);
    }

    return () => {
      clearInterval(timer);
    };
  }, [remainingTime]);

  const formatTime = (time: number) => {
    const minutes = Math.floor(time / 60000);
    const seconds = Math.floor((time % 60000) / 1000);
    return `${minutes.toString().padStart(2, "0")}:${seconds
      .toString()
      .padStart(2, "0")}`;
  };

  const handleNavigation = (route: string) => {
    navigate(route);
  };

  return (
    <div>
      <NavbarComponent disabled={true} />
      <div>
        <br />
        <br />
        <div id="startScreenOtherElements">
          <div className="auth-container">
            <div className="description">
              <h3>{welcome_headline}</h3>
              <p>{welcome_description}</p>
              <p>Viel Spaß beim Lernen!</p>
            </div>
            <div className="auth-card-container">
              {isDialogVisible ? (
                <Card className="auth-card">
                  <Card.Body>
                    <Card.Title>Login</Card.Title>
                    <Form ref={formRef} onSubmit={handleSubmit}>
                      <Form.Group controlId="formBasicUsername">
                        <Form.Control
                          type="text"
                          placeholder="Benutzername"
                          name="username"
                          className="username-input"
                          ref={usernameRef}
                        />
                      </Form.Group>
                      <Form.Group controlId="formBasicPassword">
                        <Form.Control
                          type="password"
                          placeholder="Passwort"
                          name="password"
                          className="password-input"
                          ref={passwordRef}
                        />
                      </Form.Group>
                      <br />
                      <Button
                        type="submit"
                        variant="primary"
                        className="login-btn"
                        ref={loginButtonRef}
                      >
                        Login
                      </Button>
                      <span
                        onClick={() => handleNavigation("/registrierung")}
                        style={linkStyle}
                      >
                        Registrieren
                      </span>{" "}
                    </Form>
                  </Card.Body>
                </Card>
              ) : (
                <Card className="auth-card">
                  <Card.Body>
                    <Card.Title>Zu viele falsche Versuche!</Card.Title>
                    <p>
                      Ihr Account wurde nun gesperrt! Bitte kontaktieren sie den
                      Support zum entsperren! Der Login Screen kommt in{" "}
                      {formatTime(remainingTime)} wieder.
                    </p>
                  </Card.Body>
                </Card>
              )}
            </div>
          </div>

          <div id="platform-features">
            <Card id="platform-card">
              <Card.Body>
                <Card.Title>Unsere Vorteile</Card.Title>
                <Card.Text>
                  <div className="platform-table">
                    <table>
                      <tbody>
                        <tr>
                          <td>
                            <FaClock className="icon" />
                          </td>
                          <td>
                            <FaBookOpen className="icon" />
                          </td>
                          <td>
                            <FaCode className="icon" />
                          </td>
                        </tr>
                        <tr>
                          <td>
                            Erstellen Sie eine Lernplattform mit Code-Übungen in
                            unter 20 Minuten.
                          </td>
                          <td>
                            Unsere Plattform ermöglicht leicht lesbare
                            Erklärungstexte für Ihre Studierenden.
                          </td>
                          <td>
                            Sie können komplexe Programmierübungen erstellen und
                            Studierendeneingaben korrigieren.
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <FaGraduationCap className="icon" />
                          </td>
                          <td>
                            <FaChartLine className="icon" />
                          </td>
                          <td>
                            <FaDatabase className="icon" />
                          </td>
                        </tr>
                        <tr>
                          <td>
                            Wir konzentrieren uns als einer der ersten
                            Lernplattform Creator auf den Programmierunterricht.
                          </td>
                          <td>
                            Unser Dashboard zeigt den Studierenden ihren
                            Fortschritt in einem Dashboard an.
                          </td>
                          <td>
                            Keine Übertragung von Studierendeneingaben an Server
                            zur Datenverarbeitung.
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </Card.Text>
              </Card.Body>
            </Card>
          </div>
          <br />
          <div
            className="slideshow-card"
            id="bilderPreview"
            style={{ width: "80vw" }}
          >
            <Card>
              <Carousel>
                <Carousel.Item>
                  <div className="slide-container">
                    <img
                      src={screen1}
                      alt="Screenshot 1"
                      style={{ width: "80vw" }}
                    />
                    <div className="caption-overlay"></div>
                    <div className="caption-text">
                      <h3>Leichter Login</h3>
                      <p>
                        Es ist leicht für die Studierenden, sich einzuloggen.
                        Falls gewollt kann allerdings auch aktiviert werden,
                        dass die Studierenden ohne Login-Daten die Anwendung
                        öffnen können.
                      </p>
                    </div>
                  </div>
                </Carousel.Item>
                <Carousel.Item>
                  <div className="slide-container">
                    <img
                      src={screen2}
                      alt="Screenshot 2"
                      style={{ width: "80vw" }}
                    />
                    <div className="caption-overlay"></div>
                    <div className="caption-text">
                      <h3>Übersicht über die Unterrichtseinheiten</h3>
                    </div>
                  </div>
                </Carousel.Item>
                <Carousel.Item>
                  <div className="slide-container">
                    <img
                      src={screen3}
                      alt="Screenshot 2"
                      style={{ width: "80vw" }}
                    />
                    <div className="caption-overlay"></div>
                    <div className="caption-text">
                      <h3>
                        Falls eine abgeschlossen ist, wird dies direkt mit einem
                        grünen Pfeil angezeigt.
                      </h3>
                    </div>
                  </div>
                </Carousel.Item>
                <Carousel.Item>
                  <div className="slide-container">
                    <img
                      src={screen4}
                      alt="Screenshot 2"
                      style={{ width: "80vw" }}
                    />
                    <div className="caption-overlay"></div>
                    <div className="caption-text">
                      <h3>Einfache Unterrichtseinheiten ohne Aufgabe</h3>
                      <p>
                        Falls zu einer Unterrichtseinheit keine Aufgabe
                        angedacht ist, wird ein "Abgeschlossen"-Button
                        angezeigt.
                      </p>
                    </div>
                  </div>
                </Carousel.Item>
                <Carousel.Item>
                  <div className="slide-container">
                    <img
                      src={screen5}
                      alt="Screenshot 2"
                      style={{ width: "80vw" }}
                    />
                    <div className="caption-overlay"></div>
                    <div className="caption-text">
                      <h3>Vorschau einer Code-Lösung</h3>
                      <p>
                        Falls es sich um einen HTML/CSS/JS-Code handelt, kann
                        dieser falls gewollt dem Nutzer angezeigt werden. Somit
                        kann der Anwender die Aufgabe leichter umsetzen.
                      </p>
                    </div>
                  </div>
                </Carousel.Item>
                <Carousel.Item>
                  <div className="slide-container">
                    <img
                      src={screen6}
                      alt="Screenshot 2"
                      style={{ width: "80vw" }}
                    />
                    <div className="caption-overlay"></div>
                    <div className="caption-text">
                      <h3>Online-Code-Editor</h3>
                      <p>
                        In der Online-Lernplattform kann der Anwender seinen
                        Code direkt in einer integrierten IDE schreiben.
                      </p>
                    </div>
                  </div>
                </Carousel.Item>
                <Carousel.Item>
                  <div className="slide-container">
                    <img
                      src={screen7}
                      alt="Screenshot 2"
                      style={{ width: "80vw" }}
                    />
                    <div className="caption-overlay"></div>
                    <div className="caption-text">
                      <h3>Korrektur der Lösung</h3>
                      <p>
                        Nach Eingabe einer möglichen Lösung wird die Eingabe
                        transparent mit der ursprünglichen Lösung verglichen.
                      </p>
                    </div>
                  </div>
                </Carousel.Item>
                <Carousel.Item>
                  <div className="slide-container">
                    <img
                      src={screen8}
                      alt="Screenshot 2"
                      style={{ width: "80vw" }}
                    />
                    <div className="caption-overlay"></div>
                    <div className="caption-text">
                      <h3>User-Dashboard</h3>
                      <p>
                        Der Anwender der Lernplattform sieht seinen
                        Lernfortschritt in einem Dashboard. Die Daten des
                        Fortschritts werden lokal auf dem Client gespeichert und
                        sind nur auf diesem einsehbar (nicht in der Cloud).
                      </p>
                    </div>
                  </div>
                </Carousel.Item>
              </Carousel>
              <br />
              <a
                href="https://www.webentwicklung-lernen.de/live-demo"
                style={{ textAlign: "center" }}
              >
                Live-Demo einer Lernplattform ansehen und selbst erleben!
              </a>
              <br />
            </Card>
          </div>
          <br />
          {/*  <div id="platform-features">
            <Card id="platform-card">
              <Card.Body>
                <Card.Title>
                  Abomodelle
                </Card.Title>
                <Card.Text>
                  <div >
                    <table style={{ textAlign: 'center', width: '70vw' }}>
                      <tbody>
                        <tr>
                          <td><b>Preis pro Monat</b></td>
                          <td><h5>50€</h5></td>
                          <td><h5>12 €</h5></td>
                          <td><h5>15 €</h5></td>
                          <td><h5>19,99€</h5></td>
                        </tr>
                        <tr>
                          <td><b>Mindestlaufzeit</b></td>
                          <td>12 Monat</td>
                          <td>12 Monat</td>
                          <td>3 Monate</td>
                          <td>1 Monat</td>
                        </tr>
                        <tr>
                          <td><b>Inklusive Anzahl</b></td>
                          <td>5</td>
                          <td>1</td>
                          <td>1</td>
                          <td>1</td>
                        </tr>
                        <tr>
                          <td><b>Max. Antwortzeit Support</b></td>
                          <td>24 h</td>
                          <td>7 Tage</td>
                          <td>7 Tage </td>
                          <td>7 Tage</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </Card.Text>
              </Card.Body>
            </Card>
          </div>
          */}
          <br />
        </div>
      </div>
      <FooterComponent />
    </div>
  );
};

export default PasswordDialog;
