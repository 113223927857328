import React from 'react';
import { Navbar, Container, Nav } from 'react-bootstrap';
import { Link, NavLink } from 'react-router-dom';
import { website_title } from '../modules/app_configuration/app_texts';


interface NavbarProps {
  disabled: boolean;
}

const NavbarComponent: React.FC<NavbarProps> = ({ disabled }) => {
  const isMobileDevice = window.innerWidth <= 767;
  const logoPath = process.env.PUBLIC_URL + '/logo.svg';


  return (
    <Navbar bg="light" variant="light" expand="lg" fixed="top" style={{ boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)' }}>
      <Container>
        <Navbar.Brand>
          <Container>
            <div className="d-flex align-items-center">
              <img src={logoPath} alt="Logo" style={{ height: '1.5vw' }} className="mr-2" />
              <h5 className="m-0">{website_title}</h5>
            </div>
          </Container>
        </Navbar.Brand>
        <Navbar.Toggle className="ms-auto" aria-controls="navbar-nav" />
        <Navbar.Collapse id="navbar-nav">
          <Nav className="ms-auto">
            {disabled ? (
              <>
              </>
            ) : (
              <>
                <Nav.Link as={Link} to="/plattformsOverview" disabled={disabled}>
                  Ihre Seiten
                </Nav.Link>
              </>
            )}
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};

export default NavbarComponent;
