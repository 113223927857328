import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { Card, Button, Form,Alert } from "react-bootstrap";
import NavbarComponent from "../../base/navbarComponent";
import { getApiData, makeApiRequest } from "../network/network_handler";
import { MdArrowBack } from "react-icons/md";

interface PlatformDetails {
  website_title: string;
  description: string;
  navbar_title: string;
  welcome_headline: string;
  welcome_description: string;
  footer_text: string;
  impressum_text: string;
  datenschutz_text: string;
}

const PlatformDetailsForm: React.FC = () => {
  const url = new URL(window.location.href);
  const platformID = url.pathname.split("/")[2];
  const navigate = useNavigate();

  const [platformDetails, setPlatformDetails] = useState<PlatformDetails>({
    website_title: "",
    description: "",
    navbar_title: "",
    welcome_headline: "",
    welcome_description: "",
    footer_text: "",
    impressum_text: "",
    datenschutz_text: "",
  });

  useEffect(() => {
    const requestBody = {
      plattformID: platformID,
    };

    getApiData<any>(
      `plattform_${platformID}`,
      "getPlattformDetails",
      "POST",
      requestBody
    )
      .then((data: any | null) => {
        console.log(data);

        if (data != null) {
          console.log("pass if");
          const platformDetailsData: PlatformDetails = {
            website_title: data.website_title || "",
            description: data.description || "",
            navbar_title: data.navbar_title || "",
            welcome_headline: data.welcome_headline || "",
            welcome_description: data.welcome_description || "",
            footer_text: data.footer_text || "",
            impressum_text: data.impressum_text || "",
            datenschutz_text: data.datenschutz_text || "",
          };
          platformDetailsData.datenschutz_text =
          platformDetailsData.datenschutz_text.replace(/<br \/>/g, "\n");
        platformDetailsData.impressum_text =
          platformDetailsData.impressum_text.replace(/<br \/>/g, "\n");
          
          console.log(platformDetailsData);
          setPlatformDetails(platformDetailsData);
        } else {
          console.log("Keine Daten gefunden");
          // Führe hier die entsprechende Fehlerbehandlung durch, z.B. Anzeige einer Fehlermeldung
        }
      })
      .catch((error: Error) => {
        console.error("Fehler beim Abrufen der Daten:", error);
        alert("Ein Fehler ist aufgetreten!");
      });
  }, [platformID]);

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    let platformDetails2 = platformDetails;
    platformDetails.datenschutz_text = platformDetails.datenschutz_text.replace(
      /\n/g,
      "<br />"
    );
    platformDetails.impressum_text = platformDetails.impressum_text.replace(
      /\n/g,
      "<br />"
    );

    const bodyRequest = {
      plattformID: platformID,
      website_title: platformDetails.website_title,
      description: platformDetails.description,
      navbar_title: platformDetails.navbar_title,
      welcome_headline: platformDetails.welcome_headline,
      welcome_description: platformDetails.welcome_description,
      footer_text: platformDetails.footer_text,
      impressum_text: platformDetails.impressum_text,
      datenschutz_text: platformDetails.datenschutz_text,
    };
    platformDetails.datenschutz_text = platformDetails2.datenschutz_text;
    platformDetails.impressum_text = platformDetails2.impressum_text;

    makeApiRequest<any[]>("setPlattformDetails", "POST", bodyRequest)
      .then((data: any[] | null) => {
        console.log(data);
        if (data) {
          if (sessionStorage.getItem("getPlattformDetails") === "true") {
            sessionStorage.setItem(
              `plattform_${platformID}`,
              JSON.stringify(platformDetails)
            );
          }
          console.log("Daten erfolgreich gespeichert");
          alert("Änderungen gespeichert!");
        }
      })
      .catch((error: Error) => {
        console.log("Fehler beim Speichern der Daten");
        console.log(error);
      });
  };

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { name, value } = e.target;
    setPlatformDetails((prevPlatformDetails) => ({
      ...prevPlatformDetails,
      [name]: value,
    }));
  };

  return (
    <div>
      <NavbarComponent disabled={false} />
      <div className="lesson-overview-container after-login-container">
        <Card style={{ width: "80vw", marginBottom: "2vw" }}>
          <Card.Header as="h2">
            <MdArrowBack
              style={{ cursor: "pointer" }}
              onClick={() => navigate(-1)}
            />
            Plattformdetails bearbeiten
          </Card.Header>
          <Card.Body>
            <Form onSubmit={handleSubmit}>
              <Form.Group controlId="websiteTitle">
                <div style={{ display: "flex", alignItems: "center" }}>
                  <Form.Label
                    style={{
                      alignItems: "center",
                      width: "10vw",
                      marginRight: "1rem",
                    }}
                  >
                    Website-Titel
                  </Form.Label>
                  <Form.Control
                    type="text"
                    name="website_title"
                    value={platformDetails.website_title}
                    onChange={handleChange}
                    style={{ width: "65vw" }}
                    required
                  />
                </div>
              </Form.Group>

              <Form.Group controlId="description">
                <Form.Label>Beschreibung (nicht auf der Webseite)</Form.Label>
                <Form.Control
                  as="textarea"
                  rows={3}
                  name="description"
                  value={platformDetails.description}
                  onChange={handleChange}
                />
              </Form.Group>
              <br />
              <Form.Group controlId="navbarTitle">
                <Form.Label>Navbar-Titel</Form.Label>
                <Form.Control
                  type="text"
                  name="navbar_title"
                  value={platformDetails.navbar_title}
                  onChange={handleChange}
                />
              </Form.Group>
              <br />
              <Form.Group controlId="welcomeHeadline">
                <Form.Label>Willkommensüberschrift</Form.Label>
                <Form.Control
                  type="text"
                  name="welcome_headline"
                  value={platformDetails.welcome_headline}
                  onChange={handleChange}
                />
              </Form.Group>
              <br />
              <Form.Group controlId="welcomeDescription">
                <Form.Label>Willkommens Text</Form.Label>
                <Form.Control
                  as="textarea"
                  rows={3}
                  name="welcome_description"
                  value={platformDetails.welcome_description}
                  onChange={handleChange}
                />
              </Form.Group>
              <br />
              <Form.Group controlId="footerText">
                <Form.Label>Footer-Text</Form.Label>
                <Form.Control
                  type="text"
                  name="footer_text"
                  value={platformDetails.footer_text}
                  onChange={handleChange}
                />
              </Form.Group>
              <br />
              <br />
              <Alert variant="warning">
                Bitte beachten Sie: Die Datenschutzerklärung sowie das Impressum
                müssen vor der Veröffentlichung angepasst werden, da hier
                Platzhaltertexte verwendet werden. Wir empfehlen Ihnen, die
                Texte sorgfältig zu prüfen und sich gegebenenfalls von einem
                Anwalt beraten zu lassen, da fehlerhafte oder veraltete Angaben
                zu Abmahnungen führen können. Wir übernehmen keine Haftung für
                diese Vorlagen.
              </Alert>
              <Form.Group controlId="impressum_text">
                <Form.Label>Impressum Text</Form.Label>
                <Form.Control
                  as="textarea"
                  rows={3}
                  name="impressum_text"
                  value={platformDetails.impressum_text}
                  onChange={handleChange}
                />
              </Form.Group>
              <br />
              <Form.Group controlId="datenschutz_text">
                <Form.Label>Datenverarbeitungs Text</Form.Label>
                <Form.Control
                  as="textarea"
                  rows={3}
                  name="datenschutz_text"
                  value={platformDetails.datenschutz_text}
                  onChange={handleChange}
                />
              </Form.Group>
              <br />
              <Button variant="primary" type="submit">
                Speichern
              </Button>
            </Form>
            <br />
          </Card.Body>
        </Card>
      </div>
    </div>
  );
};

export default PlatformDetailsForm;
