import React, { useState } from "react";
import { Card, Alert, Form, Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { MdArrowBack } from "react-icons/md";
import NavbarComponent from "../../base/navbarComponent";
import CryptoJS from "crypto-js"; // Import CryptoJS library
import { makeApiRequest } from "../network/network_handler";

interface RegistrationProps {
  passwordEntered: boolean;
}

const Registration: React.FC<RegistrationProps> = ({ passwordEntered }) => {
  const navigate = useNavigate();
  const [captchaValue, setCaptchaValue] = useState<number>(
    Math.floor(Math.random() * 10) + 1
  );
  const [formData, setFormData] = useState({
    repoName: "",
    userMail: "",
    password: "",
    userFirstName: "",
    userLastName: "",
    userAdresse: "",
    userTele: "",
    captchaValue: "",
  });

  const [formErrors, setFormErrors] = useState({
    emailError: false,
    teleError: false,
    passwordError: false,
    allFieldsRequiredError: false,
    sqlInjectionError: false,
    captchaError: false
  });

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const handleBackClick = () => {
    navigate(-1);
  };

  const handleSubmit = () => {
    // Check if all fields are filled
    const allFieldsFilled = Object.values(formData).every(
      (field) => field.trim() !== ""
    );
    setFormErrors((prevErrors) => ({
      ...prevErrors,
      allFieldsRequiredError: !allFieldsFilled,
    }));

    // Validations
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    setFormErrors((prevErrors) => ({
      ...prevErrors,
      emailError: !emailRegex.test(formData.userMail),
      teleError: !/^\d{8,}$/.test(formData.userTele), // At least 8 digits for phone number
      passwordError: formData.password.length <= 5,
    }));

    // Check for SQL Injection
    const sqlInjectionRegex =
      /(\bDROP\s+TABLE\b|\bDELETE\s+FROM\b|\bUPDATE\s+SET\b|\bINSERT\s+INTO\b)/i;
    setFormErrors((prevErrors) => ({
      ...prevErrors,
      sqlInjectionError: sqlInjectionRegex.test(
        Object.values(formData).join(" ")
      ), // Check all form fields as a single string
    }));

    // If all fields are valid, proceed with registration logic
    if (parseInt(formData.captchaValue) === captchaValue - 1) {
      if (
        allFieldsFilled &&
        !formErrors.emailError &&
        !formErrors.teleError &&
        !formErrors.passwordError &&
        !formErrors.sqlInjectionError
      ) {
        // Perform your registration logic here
        const requestBody = {
          userMail: formData.userMail,
          password: CryptoJS.MD5(formData.password).toString(),
          repoName: formData.repoName,
          userFirstName: formData.userFirstName,
          userLastName: formData.userLastName,
          userAdresse: formData.userAdresse,
          userTele: formData.userTele,
        };

        const path = "register";
        const method = "POST";

        // Call the API to register the user
        makeApiRequest<any[]>(path, method, requestBody)
          .then((response) => {
            // After successful registration, navigate to PlattformOverview
            navigate("/plattform-overview");
          })
          .catch((error) => {
            console.error("API error:", error);
          });
        //alert("Die Registierung ist akutell nicht möglich.");
      }
    } else {
      setFormErrors((prevErrors) => ({
        ...prevErrors,
        captchaError: true,
      }));
    }
  };

  return (
    <div>
      <NavbarComponent disabled={!passwordEntered} />

      <div className="after-login-container">
        <Card className="mb-3">
          <Card.Header as="h2">
            <MdArrowBack
              style={{ cursor: "pointer" }}
              onClick={handleBackClick}
            />
            Registrierung
          </Card.Header>
          <Card.Body>
            <Alert variant="warning">
              Bitte beachten Sie: Ihre Eingaben hier erscheinen automatisch in
              einer Vorlagen Datenschutz Seite und Impressum auf ihrerer
              Webseite. Für die korrekten Angaben haften sie! <br /> Prüfen sie
              folglich die Eingaben und überprüfen sie nach Erstellung das
              Impressum und die Datenschutz Seite.
            </Alert>
            <Form>
              <Form.Group controlId="repoName">
                <Form.Label>Webseiten Name</Form.Label>
                <Form.Control
                  type="text"
                  name="repoName"
                  value={formData.repoName}
                  onChange={handleInputChange}
                  placeholder="Webseiten Name"
                />
              </Form.Group>
              <br />

              {formErrors.emailError && (
                <Alert variant="danger">Ungültige E-Mail Adresse</Alert>
              )}
              <Form.Group controlId="userMail">
                <Form.Label>E-Mail</Form.Label>
                <Form.Control
                  type="text"
                  name="userMail"
                  value={formData.userMail}
                  onChange={handleInputChange}
                  placeholder="Email"
                />
              </Form.Group>
              <br />
              {formErrors.passwordError && (
                <Alert variant="danger">
                  Das Passwort braucht mindestens 6 Zeichen
                </Alert>
              )}
              <Form.Group controlId="password">
                <Form.Label>Passwort</Form.Label>
                <Form.Control
                  type="password"
                  name="password"
                  value={formData.password}
                  onChange={handleInputChange}
                  placeholder="Passwort"
                />
              </Form.Group>
              <br />

              <Form.Group controlId="userFirstName">
                <Form.Label>Vorname</Form.Label>
                <Form.Control
                  type="text"
                  name="userFirstName"
                  value={formData.userFirstName}
                  onChange={handleInputChange}
                  placeholder="Vorname"
                />
              </Form.Group>
              <br />

              <Form.Group controlId="userLastName">
                <Form.Label>Nachname</Form.Label>
                <Form.Control
                  type="text"
                  name="userLastName"
                  value={formData.userLastName}
                  onChange={handleInputChange}
                  placeholder="Nachname"
                />
              </Form.Group>
              <br />

              <Form.Group controlId="userAdresse">
                <Form.Label>Ihre Adresse</Form.Label>
                <Form.Control
                  type="text"
                  name="userAdresse"
                  value={formData.userAdresse}
                  onChange={handleInputChange}
                  placeholder="Adresse"
                />
              </Form.Group>
              <br />

              {formErrors.teleError && (
                <Alert variant="danger">Ungültige Telefonnummer</Alert>
              )}

              <Form.Group controlId="userTele">
                <Form.Label>Telefonnummer</Form.Label>
                <Form.Control
                  type="text"
                  name="userTele"
                  value={formData.userTele}
                  onChange={handleInputChange}
                  placeholder="Telefonnummer"
                />
              </Form.Group>

              {formErrors.captchaError && (
                <Alert variant="danger">Ungültige Eingabe</Alert>
              )}
              <Form.Group controlId="captcha">
                <Form.Label>Was ergibt {captchaValue} minus 1?</Form.Label>
                <Form.Control
                  type="text"
                  name="userTele"
                  value={formData.captchaValue}
                  onChange={handleInputChange}
                  placeholder="...?"
                />
              </Form.Group>
              <br />
              <Button variant="primary" onClick={handleSubmit}>
                Registrieren
              </Button>
              <br />
              <br />
              {formErrors.allFieldsRequiredError && (
                <Alert variant="danger">Alle Felder sind Pflicht </Alert>
              )}
              {formErrors.sqlInjectionError && (
                <Alert variant="danger">Ungültige Eingabe</Alert>
              )}
            </Form>
          </Card.Body>
        </Card>
      </div>
    </div>
  );
};

export default Registration;
