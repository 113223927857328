import React, { useEffect, useState } from "react";
import { Row, Col, Card, Button } from "react-bootstrap";

import { FaPlus, FaCog, FaEdit, FaUsers } from "react-icons/fa";
import { MdOpenInBrowser } from "react-icons/md";

import { useNavigate } from "react-router-dom";
import "./plattformsOverview.css"; // Import the CSS file
import NavbarComponent from "../../base/navbarComponent";

import { getApiData, makeApiRequest } from "../network/network_handler";

interface Site {
  id: string;
  title: string;
  description: string;
  link: string;
}

const PlattformsOverview: React.FC = () => {
  const navigate = useNavigate();
  const [sitesToEdit, setSitesToEdit] = useState<Site[]>([]);
  const [deploying, setDeploying] = useState(false);

  const handleDeploy = async (platformID: string) => {
    setDeploying(true);

    const requestBody = {
      plattformID: platformID,
    };

    const path = "deploy";
    const method = "POST";

    makeApiRequest<any[]>(path, method, requestBody);
    alert("Veröffentlichung erfolgreich gestartet! Dies kann bis zu 30 Minuten dauern!");
    setDeploying(false);
  };

  const handleNavigation = (id: string) => {
    navigate(`/${id}`); // Pass the id to the lesson route
  };

  const handleNavigationDetail = (id: string) => {
    navigate(`../platformDetails/${id}`); // Pass the id to the lesson route
  };

  const handleNavigationUsers = (id: string) => {
    navigate(`../plattformUsers/${id}`); // Pass the id to the lesson route
  };

  useEffect(() => {
    const path = "getUserPlattforms"; // Der gewünschte Pfad nach der Basis-URL
    const method = "POST";
    const requestBody = {};

    getApiData<any[]>("plattformData", path, method, requestBody)
      .then((data: any[] | null) => {
        if (data) {
          setSitesToEdit(data);
        }
      })
      .catch((error: Error) => {
        console.error("Error:", error);
      });
  }, []);

  return (
    <div>
      <NavbarComponent disabled={false} />
      <div className="lesson-overview-container after-login-container">
        <Row>
          <Col>
            <h2 className="lesson-overview-title">Ihre Lernplattformen</h2>
            {sitesToEdit.map((site: Site) => (
              <div key={site.id} className="lesson-link">
                <Card
                  className="lesson-card"
                  style={{
                    width: "80vw",
                    maxHeight: "15vw",
                    marginBottom: "2vw",
                  }}
                >
                  <Card.Body>
                    <Card.Title>{site.title}</Card.Title>
                    <Card.Text>{site.description}</Card.Text>
                  </Card.Body>
                  <Card.Footer
                    style={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <Button
                      style={{ display: "inline-block", marginRight: "10px" }}
                      onClick={() => {
                        if (site.link === "links/") {
                          alert("Sie haben sich gerade erst registriert.  Es dauert circa 30 Minuten bis sie die Seite aufrufen können. Laden sie dann die Seite erneut.");
                        } else {
                          window.open("https://" + site.link);
                        }
                      }}
                    >
                      {" "}
                      <MdOpenInBrowser size={24} color="white" />
                    </Button>

                    <div>
                      <Button
                        style={{ display: "inline-block", marginRight: "10px" }}
                        onClick={() => handleNavigationDetail(site.id)}
                      >
                        <FaCog size={24} color="white" />
                      </Button>

                      <Button
                        style={{ display: "inline-block", marginRight: "10px" }}
                        onClick={() => handleNavigationUsers(site.id)}
                      >
                        <FaUsers size={24} color="white" />
                      </Button>

                      <Button
                        style={{ display: "inline-block", marginRight: "10px" }}
                        onClick={() => handleNavigation(site.id)}
                      >
                        <FaEdit size={24} color="white" />
                      </Button>

                      {deploying ? (
                        <Button
                          disabled
                          style={{
                            display: "inline-block",
                            marginRight: "10px",
                          }}
                          variant="secondary"
                        >
                          Veröffentlichen...
                        </Button>
                      ) : (
                        <Button
                          style={{
                            display: "inline-block",
                            marginRight: "10px",
                          }}
                          onClick={() => handleDeploy(site.id)}
                        >
                          Veröffentlichen
                        </Button>
                      )}
                    </div>
                  </Card.Footer>
                </Card>
              </div>
            ))}
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default PlattformsOverview;
