import React from "react";
import { Card } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { MdArrowBack } from "react-icons/md";
import NavbarComponent from "../../base/navbarComponent";

interface DatenschutzProps {
  passwordEntered: boolean;
}

const Datenschutz: React.FC<DatenschutzProps> = ({ passwordEntered }) => {
  const navigate = useNavigate();

  return (
    <div>
      <NavbarComponent disabled={!passwordEntered} />
      <div className="after-login-container">
        <Card className="mb-3">
          <Card.Header as="h2">
            <MdArrowBack
              style={{ cursor: "pointer" }}
              onClick={() => navigate(-1)}
            />
            Unsere Datenschutzerklärung:
          </Card.Header>
          <Card.Body>
            <Card.Text>
              <b>
                This privacy policy is available in English under this German
                one (Our target market is currently Germany)
              </b>
              <br />
              <br />
              <h4>Datenschutzerklärung für Webseite</h4>
              <p>
                Vielen Dank für Ihren Besuch auf unserer Webseite. Wir legen
                großen Wert auf den Schutz Ihrer Daten und möchten Ihnen mit
                dieser Datenschutzerklärung einen groben Überblick darüber
                geben, wie wir Ihre Daten gemäß der Datenschutz-Grundverordnung
                (DSGVO), den europäischen Datenschutzgesetzen sowie dem
                California Consumer Privacy Act (CCPA) und kalifornischem Recht
                verarbeiten und schützen.
              </p>
              <h4>1. Verantwortliche Stelle</h4>
              <p>
                Verantwortlich für die Verarbeitung Ihrer Daten ist: Christian
                Scheub
                <br />
                Ziegel&auml;cker 56
                <br />
                71560 Sulzbach/Murr
                <br />
                <br />
                Kontakt Telefon: +49 176 21674883
                <br />
                Geschäftsführer/ Inhaber: Christian Scheub
                <br />
                Bei Fragen oder Anliegen zum Datenschutz können Sie uns gerne
                unter christian.developer.app@gmail.com kontaktieren. <br />
                Link zum Impressum:
                https://www.webentwicklung-lernen.de/impressum
              </p>
              <h4>2. Cookies, LocalStorage und SessionStorage</h4>
              <ul>
                <li>
                  Cookies: Cookies sind kleine Textdateien, die von einer
                  Webseite auf Ihrem Computer oder mobilen Gerät gespeichert
                  werden. Sie dienen dazu, Informationen über Ihren Besuch auf
                  der Webseite zu speichern, beispielsweise Ihre Einstellungen
                  oder Ihren Login-Status. Wir verwenden keine Cookies auf
                  unserer Webseite. (Deshalb müssen sie sich auch bei jedem
                  Aufruf neu einloggen)
                </li>
                <li>
                  LocalStorage: LocalStorage ist ein lokaler Speicher, der in
                  Ihrem Browser abgelegt wird. Er ermöglicht es Webseiten, Daten
                  auf Ihrem Gerät zu speichern, die auch nach dem Schließen des
                  Browsers erhalten bleiben. Wir verwenden keine
                  LocalStorage-Technologie auf unserer Webseite.
                </li>
                <li>
                  SessionStorage: SessionStorage ist ebenfalls ein lokaler
                  Speicher, der in Ihrem Browser abgelegt wird. Er funktioniert
                  ähnlich wie der LocalStorage, speichert jedoch nur Daten für
                  die Dauer Ihrer aktuellen Browsersitzung. Sobald Sie den
                  Browser schließen, werden die Daten gelöscht. Auf unserer
                  Webseite verwenden wir ausschließlich den Session Storage, um
                  temporäre Daten während Ihrer aktuellen Sitzung zu speichern.
                  Nach dem Schließen des Browsers werden diese Daten automatisch
                  gelöscht.
                </li>
              </ul>
              <p>
                Auf unserer Webseite verwenden wir ausschließlich den Session
                Storage zur Speicherung von Daten. Cookies, LocalStorage und
                andere Technologien werden von uns nicht verwendet.
              </p>
              <h4>3. Erhebung und Verarbeitung von Daten</h4>
              <p>
                Wir erheben und verarbeiten nur die Daten, die Sie auf unserer
                Webseite nach einem erfolgreichen Login eingeben. Vor dem Login
                werden keine Daten gespeichert; wir vergleichen lediglich die
                eingegebenen Informationen mit den korrekten Anmeldedaten.
                Personenbezogene Daten werden nur dann gespeichert oder
                verarbeitet, wenn der Benutzer solche Daten in Felder eingibt,
                die anders gekennzeichnet sind.
              </p>
              <h4>4. Speicherung und Verarbeitung der Daten</h4>
              <p>
                Nach einem erfolgreichen Login können folgende Daten gespeichert
                und verarbeitet werden:
              </p>
              <ul>
                <li>Benutzername</li>
                <li>Passwort</li>
                <li>Zuordnung der Lernplattform zum Benutzer</li>
                <li>Lizenzdauer für jeden Benutzer</li>
                <li>
                  Von dem Benutzer für die Lernplattform erstellte
                  Unterrichtseinheiten
                </li>
                <li>
                  Benutzerdaten (Benutzername, Passwort, Lizenzdauer,
                  Beschreibung), die der Benutzer für die Lernplattform
                  definiert
                </li>
                <li>Von dem Benutzer erstellter Titel für die Lernplattform</li>
                <li>
                  Von dem Benutzer erstellte Beschreibung für die Lernplattform
                </li>
                <li>
                  Texte der Navigationsleiste und andere Texte, die der Benutzer
                  für die Lernplattform erstellt
                </li>
              </ul>
              <p>
                Bitte beachten Sie, dass darüber hinaus keine personenbezogenen
                Daten gespeichert oder verarbeitet werden. Wir speichern diese
                Daten so lang wie ihre Lizenz für diese Software hält und 6
                Monate darüber hinaus. Sie haben jeder Zeit die Möglichkeit eine
                komplette Datenlöschung zu beantragen per E-Mail bei uns, falls
                diese früher stattfinden soll. Anschließend werden diese
                gelöscht.
              </p>
              <h4>5. Verkauf von Daten</h4>
              <p>
                Wir verkaufen keinerlei Ihrer Daten. Ihre Informationen werden
                ausschließlich zum Zweck der Bereitstellung der auf unserer
                Webseite angebotenen Dienste verwendet.
              </p>
              <h4>6. Ihre Rechte</h4>
              <p>
                Gemäß der DSGVO und des CCPA haben Sie verschiedene Rechte
                bezüglich Ihrer Daten. Sie haben das Recht auf Auskunft über die
                von uns verarbeiteten Daten, das Recht auf Berichtigung
                unrichtiger Daten, das Recht auf Löschung Ihrer Daten sowie das
                Recht auf Einschränkung der Verarbeitung. Darüber hinaus haben
                Sie das Recht auf Datenübertragbarkeit und das Recht, der
                Verarbeitung Ihrer Daten zu widersprechen. Um diese Rechte
                auszuüben oder die Löschung Ihrer Daten zu beantragen,
                kontaktieren Sie uns bitte per E-Mail an
                christian.developer.app@gmail.com und geben Sie Ihren
                Benutzernamen an.
              </p>
              <h4>7. Datensicherheit</h4>
              <p>
                Wir setzen angemessene technische und organisatorische Maßnahmen
                ein, um Ihre Daten vor Verlust, Missbrauch, unbefugtem Zugriff
                oder Offenlegung zu schützen. Unsere Sicherheitsmaßnahmen werden
                regelmäßig überprüft und an den aktuellen Stand der Technik
                angepasst.
              </p>
              <h4>8. Internationale Datenübertragungen</h4>
              <p>
                Da wir auf Microsoft Azure-Servern innerhalb Europas gehostet
                sind, erfolgen keine internationalen Datenübertragungen in
                Drittländer.
              </p>
              <h4>9. Änderungen dieser Datenschutzerklärung</h4>
              <p>
                Wir behalten uns das Recht vor, diese Datenschutzerklärung zu
                ändern, um den rechtlichen Anforderungen zu entsprechen oder
                Änderungen unserer Datenverarbeitungspraktiken widerzuspiegeln.
                Bitte überprüfen Sie diese Erklärung regelmäßig, um auf dem
                Laufenden zu bleiben.
              </p>
              <p>Datum: 15.07.2023</p>
              <br /> <br />
              <h2>Privacy Policy for Website</h2>
              <p>
                Thank you for visiting our website. We value the protection of
                your data and would like to provide you with a rough overview of
                how we process and protect your data in accordance with the
                General Data Protection Regulation (GDPR), EU law (for example
                the german DSVGO), the California Consumer Privacy Act (CCPA),
                and California law.
              </p>
              <h4>1. Responsible Party</h4>
              <p>
                The responsible party for processing your data is Christian
                Scheub, Ziegeläcker 56,Sulzbach an der Murr, Germany. If you
                have any questions or concerns regarding privacy, you can
                contact us at christian.developer.app@gmail.com.
              </p>
              <h4>2. Cookies, LocalStorage, and SessionStorage</h4>
              <ul>
                <li>
                  Cookies: Cookies are small text files that are stored by a
                  website on your computer or mobile device. They are used to
                  store information about your visit to the website, such as
                  your settings or login status. We do not use cookies on our
                  website. (Therefore, you will need to log in again each time
                  you visit.)
                </li>
                <li>
                  LocalStorage: LocalStorage is a local storage that is stored
                  in your browser. It allows websites to store data on your
                  device that persists even after closing the browser. We do not
                  use LocalStorage technology on our website.
                </li>
                <li>
                  SessionStorage: SessionStorage is also a local storage that is
                  stored in your browser. It works similarly to LocalStorage but
                  only stores data for the duration of your current browsing
                  session. Once you close the browser, the data is deleted. On
                  our website, we exclusively use Session Storage to store
                  temporary data during your current session. These data are
                  automatically deleted after closing the browser.
                </li>
              </ul>
              <p>
                On our website, we exclusively use Session Storage to store
                data. We do not use cookies, LocalStorage, or any other similar
                technologies.
              </p>
              <h4>3. Collection and Processing of Data</h4>
              <p>
                We only collect and process the data that you enter on our
                website after a successful login. Prior to the login, no data is
                stored; we only compare the entered information with the correct
                login data. No personal data is stored or processed unless the
                user enters such data in fields labeled differently.
              </p>
              <h4>4. Storage and Processing of Data</h4>
              <p>
                After a successful login, the following data may be stored and
                processed:
              </p>
              <ul>
                <li>Username</li>
                <li>Password</li>
                <li>Correspondence of the learning platforms to the user</li>
                <li>License duration for each user</li>
                <li>
                  Lesson units created by the user for the learning platforms
                </li>
                <li>
                  User data (username, password, license duration, description)
                  created by the user for the learning platforms
                </li>
                <li>Learning platform title created by the user</li>
                <li>Learning platform description created by the user</li>
                <li>
                  Navigation bar text and other texts created by the user for
                  the learning platforms
                </li>
              </ul>
              <p>
                Please note that no other personal data will be stored or
                processed. data is stored or processed. We store this data for
                as long as your license for this software lasts and 6 months
                beyond that. You have the possibility at any time to request a
                complete data deletion by emailing us, if you want this should
                take place earlier. Afterwards these data will be deleted.
              </p>
              <h4>5. Selling of Data</h4>
              <p>
                We do not sell any of your data. Your information is used solely
                for the purpose of providing the services offered on our
                website.
              </p>
              <h4>6. Your Rights</h4>
              <p>
                Under the GDPR and the CCPA, you have various rights regarding
                your data. You have the right to access the data we process, the
                right to correct inaccurate data, the right to have your data
                deleted, and the right to restrict processing. Additionally, you
                have the right to data portability and the right to object to
                the processing of your data. To exercise these rights or request
                the deletion of your data, please contact us via email at
                christian.developer.app@gmail.com and provide your username.
              </p>
              <h4>7. Data Security</h4>
              <p>
                We implement reasonable technical and organizational measures to
                protect your data against loss, misuse, unauthorized access, or
                disclosure. Our security measures are regularly reviewed and
                updated to comply with current technological standards.
              </p>
              <h4>8. International Data Transfers</h4>
              <p>
                Since we are hosted on Microsoft Azure servers located within
                Europe, there are no international data transfers to third
                countries.
              </p>
              <h4>9. Changes to this Privacy Policy</h4>
              <p>
                We reserve the right to make changes to this privacy policy to
                comply with legal requirements or reflect changes in our data
                processing practices. Please review this statement regularly to
                stay informed.
              </p>
              <p>Date: 15.07.2023</p>
            </Card.Text>
          </Card.Body>
        </Card>
      </div>
    </div>
  );
};

export default Datenschutz;
