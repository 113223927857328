import CryptoJS from 'crypto-js';
import { makeApiRequest } from '../network/network_handler';


async function signIn(username: string, password: string): Promise<any> {
  const requestBody = {
    username: CryptoJS.MD5(username).toString(),
    password: CryptoJS.MD5(password).toString(),
  };

  const path = 'login';
  const method = 'POST';

  return (makeApiRequest<any[]>(path, method, requestBody));
};


  export { signIn };